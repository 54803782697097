import { Retailer } from 'src/app/shared/providers/interfaces';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { NavController } from '@ionic/angular';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public ngZone: NgZone,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public navCTRL: NavController,
    private remoteConfig: AngularFireRemoteConfig
  ) {
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        if (environment.uid.includes(user.uid)) {
          this.navCTRL.navigateForward(['retailers']);
        } else {
          this.signOut();
        }
      } else {
        this.navCTRL.navigateBack(['signIn']);
      }
    });
  }

  async signIn(email: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async signOut() {
    await this.afAuth.signOut();
  }

  async fetchAllRetailers() {
    let retailersData = [];
    let retailersDoc = this.afs.firestore.collection(`retailers`);

    await retailersDoc
      .orderBy('editedAt', 'desc')
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          docData.retailerId = doc.id;
          retailersData.push(docData);
        });

      });
    return retailersData;
  }
  async fetchRetailersCategories() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('retailer_categories');
    let obj = JSON.parse(res);
    let categories = [];
    for (var i in obj) {
      categories.push(obj[i]);
    }
    return categories[0];
  }
  async fetchNetwork() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('networks');
    let obj = JSON.parse(res);
    let network = [];

    for (var i in obj) {
      network.push(obj[i]);
    }

    return network[0];
  }
  async fetchCommissionType() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('commission_types');
    let obj = JSON.parse(res);
    let commissionType = [];

    for (var i in obj) {
      commissionType.push(obj[i]);
    }
    return commissionType;
  }
  async fetchSupportedCountries() {
    await this.remoteConfig.fetchAndActivate();
    const res = await this.remoteConfig.getString('supported_countries');
    let obj = JSON.parse(res);
    let countries = [];

    for (var i in obj) {
      countries.push(obj[i]);
    }

    return countries[0];
  }

  async deleteRetailer(retailerId) {
    const retailersDoc = this.afs.firestore
      .collection(`retailers`)
      .doc(retailerId);
    await retailersDoc.delete();
  }
  async submitRetailer(retailer: Retailer) {
    console.log(retailer)
    if (
      !retailer.image.startsWith('http') ||
      !retailer.logo.startsWith('http')
    ) {
      try {
        const imagePath = `${retailer.retailerId}/images/image`;
        const logoPath = `${retailer.retailerId}/logos/logo`;
        const imageURL = (await this.uploadImage(
          imagePath,
          retailer.image
        )) as string;
        const logoURL = (await this.uploadImage(
          logoPath,
          retailer.logo
        )) as string;
        retailer.image = imageURL;
        retailer.logo = logoURL;
      }
      catch (error) {
        console.error(error)
      }

    }
    const retailersDoc = this.afs.firestore
      .collection(`retailers`)
      .doc(retailer.retailerId);
    await retailersDoc.set(retailer);
    return true;
  }
  async uploadImage(imagePath, imageBase64) {
/*
    .ref('images')
    .child(file.name)
    .put(file);*/
    console.log(imageBase64 + ' '  + imageBase64)
    
    return new Promise(function (resolve, reject) {
      const uploadTask = firebase
        .app()
        .storage(environment.firebase.storageBucket)
        .ref(imagePath)
        .putString(imageBase64, 'data_url');
        //.putString(imageBase64, 'data_url');
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        function error(err) {
          console.log('error', err);
          reject();
        },
        function complete() {
          console.log('complete')
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            resolve(downloadURL);
          });
        }
      );
    });
  }
}
