// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCcjo0DLXTTkysvb1fFHnWHLTQIYIUPQ68',
    authDomain: 'satsy-sandbox.firebaseapp.com',
    databaseURL: 'https://satsy-sandbox.firebaseio.com',
    projectId: 'satsy-sandbox',
    storageBucket: 'gs://satsy-sandbox-retailer-images',
    messagingSenderId: '35039295450',
    appId: '1:35039295450:web:bfb0adfca6a41db08b72e3',
    measurementId: 'G-8K4M3SXDV5',
  },
  uid: [
    'tV6eXMibosfvNPJ94t77GaeoBiH3',
    'EbNL74paYtYO9OUMzXWtmDQwKVj2',
    'jbEsEgp6AkZyw68wgujjqIcbJtw1',
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
