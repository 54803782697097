import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './pages/signIn/signIn';
import { ViewRetailersPage } from './pages/retailers/viewRetailers/viewRetailers';
import { AuthGuard } from './shared/guard/auth.guard';
import { UsersComponent } from './pages/users/users.component';

const routes: Routes = [
  {
    path: 'retailers',
    canActivate: [AuthGuard],
    component: ViewRetailersPage,
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    component: UsersComponent,
  },
  {
    path: 'signIn',
    component: SignInComponent,
    canActivateChild: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'retailers',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
