import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import firebase from 'firebase';
import 'firebase/auth';
import { NavController } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public navCTRL: NavController) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (firebase.auth().currentUser) {
      return true;
    } else {
      this.navCTRL.navigateBack(['signIn']);
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (firebase.auth().currentUser) {
      this.navCTRL.navigateForward(['retailers']);
    } else {
      return true;
    }
  }
}
