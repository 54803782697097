import { NgZone } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ApiService } from '../../shared/service/api.service';

@Component({
  selector: 'app-signIn',
  templateUrl: './signIn.html',
  styleUrls: ['./signIn.scss'],
})
export class SignInComponent implements OnInit {
  @ViewChild('userName') username;
  @ViewChild('userPassword') password;

  public error: string = null;
  showSpinner: boolean = false;

  constructor(
    public apiService: ApiService,
    public router: Router,
    public ngZone: NgZone,
    public menu: MenuController
  ) {
    this.menu.enable(false);
    this.menu.swipeGesture(false);
  }

  ngOnInit() {}

  signIn() {
    this.error = null;
    this.showSpinner = true;
    this.apiService
      .signIn(this.getEmail(), this.getPassword())
      .then(() => {
        this.showSpinner = false;
      })
      .catch((err) => {
        this.showSpinner = false;
        this.error = err.message;
      });
  }

  getEmail() {
    return this.username.nativeElement.value;
  }

  getPassword() {
    return this.password.nativeElement.value;
  }
}
