import { ApiService } from 'src/app/shared/service/api.service';
import { CommissionRatesInterface } from '../../shared/providers/interfaces';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-commission-rates',
  templateUrl: './commission-rates.component.html',
  styleUrls: ['./commission-rates.component.scss'],
})
export class CommissionRatesComponent implements OnInit {
  commissionData = {
    amount: '',
    description: '',
    action: '',
    type: '',
  };
  commissionType: any[];
  currentCommissionType = '';

  constructor(
    public dialogRef: MatDialogRef<CommissionRatesComponent>,
    public apiService: ApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CommissionRatesInterface
  ) {
    this.commissionData.action = data.action;
    this.getCommissionType();
  }

  async getCommissionType() {
    const commission = await this.apiService.fetchCommissionType();
    this.commissionType = commission;
    this.commissionData.type = commission[0];
  }

  doAction() {
    this.dialogRef.close({
      event: this.commissionData.action,
      data: this.commissionData,
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnInit() {}
}
