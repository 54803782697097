import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  LoadingController,
  ModalController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import * as firebase from 'firebase';
import { ReadFile, ReadMode } from 'ngx-file-helpers';
import { CommissionRatesComponent } from 'src/app/components/commission-rates/commission-rates.component';
import { Retailer } from 'src/app/shared/providers/interfaces';
import { ApiService } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-addRetailers',
  templateUrl: './addRetailers.html',
  styleUrls: ['./addRetailers.scss'],
})
export class AddRetailersPage implements OnInit {
  retailerData: Retailer = {
    displayName: '',
    clickParam: '',
    importantInfo: '',
    url: '',
    affiliateUrl: '',
    displayNameLower: '',
    extension: false,
    image: '',
    pendingPeriod: 30,
    countries: [],
    highestCommissionType: '',
    name: '',
    description: '',
    networkId: '',
    featured: false,
    keywords: [],
    status: 'inactive',
    commissionSplit: 0.5,
    logo: '',
    rates: [
      {
        description: '',
        amount: null,
        type: '',
      },
    ],
    merchantId: '',
    terms: '',
    popularity: null,
    network: '',
    highestCommissionSearch: '',
    highestCommissionAmount: null,
    userRates: [
      {
        type: '',
        amount: null,
        description: '',
      },
    ],
    id: '',
    categories: [],
    retailerId: '',
    additionalURLs: [],
    twitterId: '',
    facebookId: '',
    editedAt: firebase.default.firestore.FieldValue.serverTimestamp()
  };
  commissionType: any[];
  networkList: any;
  categoriesList: any;
  countriesList: any;
  retailerKeywords: any;
  retaileradditionalURLs: any;
  public readMode = ReadMode.dataURL;
  displayedColumns = ['amount', 'type', 'description', 'action'];
  editMode: boolean;
  constructor(
    public loadingController: LoadingController,
    public apiService: ApiService,
    public modalCTRL: ModalController,
    public navParams: NavParams,
    public cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public toastController: ToastController
  ) {}

  ngOnInit() {
    this.getRetailerDetail();
    this.getCountries();
    this.getCategories();
    this.getNetwork();
    this.getCommissionType();
  }
  getRetailerDetail() {
    if (this.navParams.get('modalType') != 'add') {
      const retailer = this.navParams.get('modalType');
      this.retailerData = retailer;
      this.retailerKeywords = this.retailerData.keywords
        ? this.retailerData.keywords.toString()
        : '';
      this.retaileradditionalURLs = this.retailerData.additionalURLs
        ? this.retailerData.additionalURLs.toString()
        : '';
      this.editMode = true;
    } else {
      this.retailerData.rates.pop();
      this.retailerData.userRates.pop();
    }
  }
  async getCountries() {
    const countries = await this.apiService.fetchSupportedCountries();
    this.countriesList = countries;
  }
  async getCategories() {
    const categories = await this.apiService.fetchRetailersCategories();
    this.categoriesList = categories;
  }
  async getNetwork() {
    const network = await this.apiService.fetchNetwork();
    this.networkList = network;
    this.retailerData.networkId =
      this.retailerData.networkId == ''
        ? network[0].id.toString()
        : this.retailerData.networkId;
    this.retailerData.network == ''
      ? network[0].name
      : this.retailerData.network;
    this.retailerData.clickParam == ''
      ? network[0].clickParam
      : this.retailerData.clickParam;
  }
  async getCommissionType() {
    const commission = await this.apiService.fetchCommissionType();
    this.commissionType = commission;
  }
  onChangeNetwork(network: any, event: any) {
    if (event.isUserInput) { 
      this.retailerData.networkId = network.id.toString();
      this.retailerData.network = network.name;
      this.retailerData.clickParam = network.clickParam;
    }
  }
  setCommissionUpToType(value) {
    this.retailerData.highestCommissionSearch = value == '%' ? '%' : '$';
  }
  openDialogCommissionRates(action: any, obj: any, index: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(CommissionRatesComponent, {
      width: '250px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addCommissionRates(result.data);
      } else if (result.event == 'Delete') {
        this.deleteCommissionRates(result.data, index);
      }
    });
  }

  addCommissionRates(rowObj: { amount: any; type: any; description: any }) {
    this.retailerData.rates.push({
      amount: rowObj.amount,
      type: rowObj.type,
      description: rowObj.description,
    });
    this.retailerData.userRates.push({
      amount: rowObj.amount,
      type: rowObj.type,
      description: rowObj.description,
    });
    this.retailerData.rates = [...this.retailerData.rates];
    this.retailerData.userRates = [...this.retailerData.userRates];
  }

  deleteCommissionRates(rowObj: any, index: number) {
    this.retailerData.rates.splice(index, 1);
    this.retailerData.userRates.splice(index, 1);
    this.retailerData.rates = [...this.retailerData.rates];
    this.retailerData.userRates = [...this.retailerData.userRates];
  }

  async onImagePicked(file: ReadFile) {
    this.retailerData.image = file.content;
  }
  async onLogoPicked(file: ReadFile) {
    this.retailerData.logo = file.content;
  }
  setKeywordsAsArray() {
    this.retailerData.keywords = this.retailerKeywords
      .split(',')
      .map((item: any) => item.trim());
  }
  setAdditionalURLAsArray() {
    this.retailerData.additionalURLs = this.retaileradditionalURLs
      .split(',')
      .map((item: any) => item.trim());
  }
  checkRequiredThings() {
    if (
      this.retailerData.displayName == '' ||
      this.retailerData.clickParam == '' ||
      this.retailerData.importantInfo == '' ||
      this.retailerData.url == '' ||
      this.retailerData.affiliateUrl == '' ||
      this.retailerData.image == '' ||
      !this.retailerData.countries.length ||
      this.retailerData.highestCommissionType == '' ||
      this.retailerData.name == '' ||
      this.retailerData.description == '' ||
      // !this.retailerData.keywords.length ||
      this.retailerData.commissionSplit == null ||
      this.retailerData.logo == '' ||
      !this.retailerData.rates.length ||
      this.retailerData.terms == '' ||
      this.retailerData.popularity == null ||
      this.retailerData.merchantId == '' ||
      this.retailerData.merchantId.length < 3 ||
      this.retailerData.networkId == null ||
      !this.retailerData.categories.length //||
      //   !this.retailerData.additionalURLs.length ||
      //this.retailerData.twitterId == '' ||
      //this.retailerData.facebookId == ''
    ) {
      return true;
    } else {
      return false;
    }
  }
  goBack() {
    this.modalCTRL.dismiss({ submitted: false });
  }
  async submitRetailer() {
    const loading = await this.loadingController.create({
      message: 'Please Wait...',
    });
    await loading.present();
    this.retailerData.displayNameLower =
      this.retailerData.displayName.toLowerCase();
    this.retailerData.id = `${this.retailerData.merchantId}.${this.retailerData.networkId}`;
    this.retailerData.retailerId = `${this.retailerData.merchantId}.${this.retailerData.networkId}`;
    this.retailerData.editedAt = firebase.default.firestore.FieldValue.serverTimestamp()
    this.apiService
      .submitRetailer(this.retailerData)
      .then(async (data) => {
        loading.dismiss();
        this.goBack();
        const toast = await this.toastController.create({
          message: `${this.retailerData.displayName} Has Been Saved`,
          duration: 3000,
        });
        toast.present();
        toast.onDidDismiss().then(() => {
          this.modalCTRL.dismiss({ submitted: true });
        });
      })
      .catch((e) => {
        loading.dismiss();
      });
  }
}
