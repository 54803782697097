import { Retailer } from 'src/app/shared/providers/interfaces';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
} from '@ionic/angular';
import { ApiService } from 'src/app/shared/service/api.service';
import { AddRetailersPage } from '../addRetailer/addRetailers';

@Component({
  selector: 'app-viewRetailers',
  templateUrl: './viewRetailers.html',
  styleUrls: ['./viewRetailers.scss'],
})
export class ViewRetailersPage implements OnInit {
  retailersList: any[];
  retailersListSearchable: any[];
  pageNumber: any = 1;
  constructor(
    public loadingController: LoadingController,
    public menu: MenuController,
    public apiService: ApiService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menu.enable(true);
    this.menu.swipeGesture(true);
    this.getRetailers();
  }
  async getRetailers() {
    const loading = await this.loadingController.create({
      message: 'Loading Retailers...',
    });
    await loading.present();
    const retailers = await this.apiService.fetchAllRetailers();
    this.retailersList = retailers;
    this.retailersListSearchable = retailers;
    loading.dismiss();
    this.cdr.detectChanges();
  }

  async addRetailer(retailerType) {
    console.log(retailerType);
    const modal = await this.modalController.create({
      component: AddRetailersPage,
      backdropDismiss: false,
      cssClass: 'fullScreenModal',
      componentProps: {
        modalType: retailerType,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.getRetailers();
  }
  async deleteRetailer(retailer: Retailer, index: number) {
    const alert = await this.alertController.create({
      message: `Do you want to delete ${retailer.displayName}`,
      buttons: [
        {
          text: 'Okay',
          handler: async () => {
            await this.apiService.deleteRetailer(retailer.retailerId);
            this.retailersListSearchable.splice(index, 1);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            //console.log('Confirm Cancel: blah');
          },
        },
      ],
    });
    await alert.present();
  }
  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }
  searchRetailer(e) {
    const retailerName = e.detail.value;
    this.retailersListSearchable = this.retailersList.filter((i) =>
      i.displayName.toLowerCase().includes(retailerName.toLowerCase())
    );
    this.pageNumber = 1;
  }
}
